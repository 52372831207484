import SiteLayout from "@/Layouts/SiteLayout";
import { Head } from '@inertiajs/react'


export default function AboutUs() {
    return <>
        <Head>
        <title>404</title>
        </Head>

        <SiteLayout>
            <div className="container mx-auto my-8 px-5 2xl:px-0 text-center">
                <h1 className="text-[40px] lg:text-[75px] font-semibold mb-4">Page not found</h1>
                <div className="text-[20px] lg:text-[30px] mb-0 lg:mb-8">Sorry, we couldn’t find the page you are looking for</div>
                <div className="flex justify-center">
                    <svg width="729" height="363" viewBox="0 0 729 363" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="path-1-outside-1_1336_44451" maskUnits="userSpaceOnUse" x="12" y="69" width="177" height="225" fill="black">
                        <rect fill="white" x="12" y="69" width="177" height="225"/>
                        <path d="M15.4474 250.517V219.089L108.026 72.8182H134.233V117.562H118.253L55.9304 216.32V218.024H185.156V250.517H15.4474ZM119.531 291V240.929L119.957 226.866V72.8182H157.244V291H119.531Z"/>
                        </mask>
                        <path d="M15.4474 250.517V219.089L108.026 72.8182H134.233V117.562H118.253L55.9304 216.32V218.024H185.156V250.517H15.4474ZM119.531 291V240.929L119.957 226.866V72.8182H157.244V291H119.531Z" fill="#B5B5B5"/>
                        <path d="M15.4474 250.517H12.4474V253.517H15.4474V250.517ZM15.4474 219.089L12.9125 217.485L12.4474 218.22V219.089H15.4474ZM108.026 72.8182V69.8182H106.374L105.491 71.2138L108.026 72.8182ZM134.233 72.8182H137.233V69.8182H134.233V72.8182ZM134.233 117.562V120.562H137.233V117.562H134.233ZM118.253 117.562V114.562H116.599L115.716 115.961L118.253 117.562ZM55.9304 216.32L53.3933 214.719L52.9304 215.452V216.32H55.9304ZM55.9304 218.024H52.9304V221.024H55.9304V218.024ZM185.156 218.024H188.156V215.024H185.156V218.024ZM185.156 250.517V253.517H188.156V250.517H185.156ZM119.531 291H116.531V294H119.531V291ZM119.531 240.929L116.533 240.838L116.531 240.884V240.929H119.531ZM119.957 226.866L122.956 226.957L122.957 226.912V226.866H119.957ZM119.957 72.8182V69.8182H116.957V72.8182H119.957ZM157.244 72.8182H160.244V69.8182H157.244V72.8182ZM157.244 291V294H160.244V291H157.244ZM18.4474 250.517V219.089H12.4474V250.517H18.4474ZM17.9824 220.694L110.561 74.4226L105.491 71.2138L12.9125 217.485L17.9824 220.694ZM108.026 75.8182H134.233V69.8182H108.026V75.8182ZM131.233 72.8182V117.562H137.233V72.8182H131.233ZM134.233 114.562H118.253V120.562H134.233V114.562ZM115.716 115.961L53.3933 214.719L58.4675 217.921L120.79 119.164L115.716 115.961ZM52.9304 216.32V218.024H58.9304V216.32H52.9304ZM55.9304 221.024H185.156V215.024H55.9304V221.024ZM182.156 218.024V250.517H188.156V218.024H182.156ZM185.156 247.517H15.4474V253.517H185.156V247.517ZM122.531 291V240.929H116.531V291H122.531ZM122.53 241.02L122.956 226.957L116.959 226.776L116.533 240.838L122.53 241.02ZM122.957 226.866V72.8182H116.957V226.866H122.957ZM119.957 75.8182H157.244V69.8182H119.957V75.8182ZM154.244 72.8182V291H160.244V72.8182H154.244ZM157.244 288H119.531V294H157.244V288Z" fill="#373434" mask="url(#path-1-outside-1_1336_44451)"/>
                        <g clip-path="url(#clip0_1336_44451)">
                        <path d="M367.955 237.869C400.866 237.869 427.545 211.214 427.545 178.333C427.545 145.451 400.866 118.796 367.955 118.796C335.045 118.796 308.365 145.451 308.365 178.333C308.365 211.214 335.045 237.869 367.955 237.869Z" fill="#373434" stroke="#FAFAFA"/>
                        <path d="M367.955 254.53C410.075 254.53 444.221 220.415 444.221 178.333C444.221 136.25 410.075 102.135 367.955 102.135C325.835 102.135 291.689 136.25 291.689 178.333C291.689 220.415 325.835 254.53 367.955 254.53Z" stroke="#373434"/>
                        <path d="M367.954 271.19C419.284 271.19 460.895 229.616 460.895 178.333C460.895 127.049 419.284 85.4752 367.954 85.4752C316.625 85.4752 275.014 127.049 275.014 178.333C275.014 229.616 316.625 271.19 367.954 271.19Z" stroke="#373434"/>
                        <path d="M367.955 217.876C389.814 217.876 407.534 200.172 407.534 178.333C407.534 156.493 389.814 138.789 367.955 138.789C346.095 138.789 328.375 156.493 328.375 178.333C328.375 200.172 346.095 217.876 367.955 217.876Z" fill="#373434" stroke="#FAFAFA"/>
                        <path d="M367.955 204.548C382.446 204.548 394.194 192.811 394.194 178.333C394.194 163.854 382.446 152.117 367.955 152.117C353.463 152.117 341.716 163.854 341.716 178.333C341.716 192.811 353.463 204.548 367.955 204.548Z" fill="#373434" stroke="#FAFAFA"/>
                        <path d="M362.953 56.711C364.795 56.711 366.288 55.2191 366.288 53.3789C366.288 51.5386 364.795 50.0468 362.953 50.0468C361.111 50.0468 359.618 51.5386 359.618 53.3789C359.618 55.2191 361.111 56.711 362.953 56.711Z" fill="#373434"/>
                        <path d="M360.982 45.2001L360.375 47.0176L357.343 43.3826L358.556 48.8351L356.737 47.0176L357.949 49.4409H351.279L357.343 51.2584L353.705 52.4701L356.737 53.6818L352.492 55.4993L356.737 54.8934L355.524 57.3168L357.949 56.7109L358.556 57.9227L359.162 59.7402L358.556 63.3752L360.982 59.7402H362.801H365.226L368.258 62.1635L366.439 58.5284L367.045 57.9227L369.471 58.5284L368.865 55.4993L371.29 54.2877L368.865 53.0759V51.2584V50.0468L370.683 48.2293L367.652 48.8351L366.439 48.2293V47.0176L367.652 43.9884L364.62 47.0176H364.013L363.407 44.5942L362.195 46.4118H361.588L360.982 45.2001Z" stroke="black" stroke-width="0.5"/>
                        <path d="M257.896 168.337C259.738 168.337 261.231 166.845 261.231 165.004C261.231 163.164 259.738 161.672 257.896 161.672C256.054 161.672 254.561 163.164 254.561 165.004C254.561 166.845 256.054 168.337 257.896 168.337Z" fill="#373434"/>
                        <path d="M244.556 248.307C246.398 248.307 247.891 246.815 247.891 244.975C247.891 243.135 246.398 241.643 244.556 241.643C242.714 241.643 241.221 243.135 241.221 244.975C241.221 246.815 242.714 248.307 244.556 248.307Z" fill="#373434"/>
                        <path d="M342.942 313.283C344.783 313.283 346.277 311.791 346.277 309.951C346.277 308.111 344.783 306.619 342.942 306.619C341.1 306.619 339.606 308.111 339.606 309.951C339.606 311.791 341.1 313.283 342.942 313.283Z" fill="#373434"/>
                        <path d="M459.669 274.964C461.511 274.964 463.004 273.472 463.004 271.632C463.004 269.792 461.511 268.3 459.669 268.3C457.827 268.3 456.334 269.792 456.334 271.632C456.334 273.472 457.827 274.964 459.669 274.964Z" fill="#373434"/>
                        <path d="M486.352 204.989C488.194 204.989 489.687 203.497 489.687 201.657C489.687 199.817 488.194 198.325 486.352 198.325C484.51 198.325 483.017 199.817 483.017 201.657C483.017 203.497 484.51 204.989 486.352 204.989Z" fill="#373434"/>
                        <path d="M286.244 78.3696C288.086 78.3696 289.579 76.8778 289.579 75.0375C289.579 73.1973 288.086 71.7054 286.244 71.7054C284.402 71.7054 282.909 73.1973 282.909 75.0375C282.909 76.8778 284.402 78.3696 286.244 78.3696Z" fill="#373434"/>
                        <path d="M466.34 105.026C468.182 105.026 469.675 103.535 469.675 101.694C469.675 99.854 468.182 98.3622 466.34 98.3622C464.498 98.3622 463.005 99.854 463.005 101.694C463.005 103.535 464.498 105.026 466.34 105.026Z" fill="#373434"/>
                        <path d="M255.926 156.825L255.319 158.643L252.287 155.008L253.5 160.46L251.681 158.643L252.894 161.067H246.224L252.287 162.884L248.649 164.095L251.681 165.307L247.436 167.124L251.681 166.519L250.468 168.942L252.894 168.336L253.5 169.548L254.107 171.366L253.5 175.001L255.926 171.366H257.745H260.17L263.202 173.789L261.383 170.154L261.99 169.548L264.415 170.154L263.809 167.124L266.234 165.913L263.809 164.702V162.884V161.672L265.628 159.855L262.596 160.46L261.383 159.855V158.643L262.596 155.614L259.564 158.643H258.958L258.351 156.22L257.139 158.037H256.532L255.926 156.825Z" stroke="black" stroke-width="0.5"/>
                        <path d="M340.97 301.772L340.364 303.59L337.333 299.954L338.545 305.407L336.726 303.59L337.939 306.013H331.269L337.333 307.83L333.694 309.042L336.726 310.254L332.481 312.071L336.726 311.465L335.513 313.889L337.939 313.283L338.545 314.494L339.151 316.312L338.545 319.948L340.97 316.312H342.79H345.216L348.247 318.736L346.428 315.101L347.034 314.494L349.46 315.101L348.854 312.071L351.279 310.859L348.854 309.648V307.83V306.619L350.673 304.801L347.641 305.407L346.428 304.801V303.59L347.641 300.56L344.609 303.59H344.003L343.396 301.166L342.183 302.983H341.577L340.97 301.772Z" stroke="black" stroke-width="0.5"/>
                        <path d="M242.586 236.796L241.979 238.614L238.948 234.978L240.16 240.431L238.341 238.614L239.554 241.037H232.884L238.948 242.854L235.309 244.066L238.341 245.278L234.097 247.095L238.341 246.489L237.128 248.913L239.554 248.307L240.16 249.518L240.767 251.336L240.16 254.971L242.586 251.336H244.405H246.831L249.863 253.76L248.043 250.125L248.65 249.518L251.075 250.125L250.469 247.095L252.894 245.884L250.469 244.672V242.854V241.642L252.288 239.825L249.256 240.431L248.043 239.825V238.614L249.256 235.585L246.224 238.614H245.618L245.011 236.19L243.799 238.007H243.192L242.586 236.796Z" stroke="black" stroke-width="0.5"/>
                        <path d="M486.048 193.479L485.441 195.296L482.41 191.662L483.623 197.114L481.803 195.296L483.016 197.719H476.346L482.41 199.537L478.771 200.749L481.803 201.96L477.559 203.778L481.803 203.172L480.59 205.595L483.016 204.99L483.623 206.201L484.229 208.019L483.623 211.654L486.048 208.019H487.867H490.293L493.324 210.442L491.505 206.807L492.111 206.201L494.537 206.807L493.931 203.778L496.357 202.566L493.931 201.355V199.537V198.326L495.75 196.508L492.718 197.114L491.505 196.508V195.296L492.718 192.267L489.687 195.296H489.08L488.474 192.872L487.261 194.69H486.654L486.048 193.479Z" stroke="black" stroke-width="0.5"/>
                        <path d="M284.274 66.8588L283.668 68.6763L280.636 65.0413L281.849 70.4938L280.03 68.6763L281.242 71.0996H274.572L280.636 72.9171L276.998 74.1288L280.03 75.3405L275.785 77.158L280.03 76.5521L278.817 78.9755L281.242 78.3696L281.849 79.5814L282.455 81.3989L281.849 85.0339L284.274 81.3989H286.094H288.519L291.551 83.8222L289.732 80.1871L290.338 79.5814L292.764 80.1871L292.157 77.158L294.583 75.9463L292.157 74.7346V72.9171V71.7055L293.977 69.8879L290.945 70.4938L289.732 69.8879V68.6763L290.945 65.6471L287.913 68.6763H287.306L286.7 66.2529L285.487 68.0704H284.881L284.274 66.8588Z" stroke="black" stroke-width="0.5"/>
                        <path d="M457.7 263.453L457.093 265.271L454.061 261.635L455.274 267.088L453.455 265.271L454.668 267.693H447.997L454.061 269.511L450.423 270.723L453.455 271.935L449.21 273.752L453.455 273.146L452.242 275.57L454.668 274.964L455.274 276.175L455.88 277.992L455.274 281.628L457.7 277.992H459.518H461.944L464.977 280.416L463.157 276.782L463.764 276.175L466.188 276.782L465.582 273.752L468.008 272.54L465.582 271.328V269.511V268.3L467.401 266.482L464.37 267.088L463.157 266.482V265.271L464.37 262.241L461.338 265.271H460.731L460.125 262.847L458.912 264.664H458.306L457.7 263.453Z" stroke="black" stroke-width="0.5"/>
                        <path d="M464.37 93.5155L463.763 95.333L460.731 91.698L461.944 97.1505L460.124 95.333L461.337 97.7564H454.667L460.731 99.5739L457.093 100.786L460.124 101.997L455.88 103.815L460.124 103.209L458.911 105.632L461.337 105.026L461.944 106.238L462.55 108.056L461.944 111.691L464.37 108.056H466.188H468.614L471.646 110.479L469.827 106.844L470.433 106.238L472.858 106.844L472.252 103.815L474.678 102.603L472.252 101.391V99.5739V98.3621L474.071 96.5446L471.04 97.1505L469.827 96.5446V95.333L471.04 92.3038L468.008 95.333H467.401L466.795 92.9096L465.582 94.7271H464.975L464.37 93.5155Z" stroke="black" stroke-width="0.5"/>
                        <path d="M367.954 181.665C369.796 181.665 371.289 180.173 371.289 178.333C371.289 176.492 369.796 175 367.954 175C366.112 175 364.619 176.492 364.619 178.333C364.619 180.173 366.112 181.665 367.954 181.665Z" fill="#FAFAFA"/>
                        </g>
                        <mask id="path-25-outside-2_1336_44451" maskUnits="userSpaceOnUse" x="541" y="69" width="177" height="225" fill="black">
                        <rect fill="white" x="541" y="69" width="177" height="225"/>
                        <path d="M544.447 250.517V219.089L637.026 72.8182H663.233V117.562H647.253L584.93 216.32V218.024H714.156V250.517H544.447ZM648.531 291V240.929L648.957 226.866V72.8182H686.244V291H648.531Z"/>
                        </mask>
                        <path d="M544.447 250.517V219.089L637.026 72.8182H663.233V117.562H647.253L584.93 216.32V218.024H714.156V250.517H544.447ZM648.531 291V240.929L648.957 226.866V72.8182H686.244V291H648.531Z" fill="#B5B5B5"/>
                        <path d="M544.447 250.517H541.447V253.517H544.447V250.517ZM544.447 219.089L541.913 217.485L541.447 218.22V219.089H544.447ZM637.026 72.8182V69.8182H635.374L634.491 71.2138L637.026 72.8182ZM663.233 72.8182H666.233V69.8182H663.233V72.8182ZM663.233 117.562V120.562H666.233V117.562H663.233ZM647.253 117.562V114.562H645.599L644.716 115.961L647.253 117.562ZM584.93 216.32L582.393 214.719L581.93 215.452V216.32H584.93ZM584.93 218.024H581.93V221.024H584.93V218.024ZM714.156 218.024H717.156V215.024H714.156V218.024ZM714.156 250.517V253.517H717.156V250.517H714.156ZM648.531 291H645.531V294H648.531V291ZM648.531 240.929L645.533 240.838L645.531 240.884V240.929H648.531ZM648.957 226.866L651.956 226.957L651.957 226.912V226.866H648.957ZM648.957 72.8182V69.8182H645.957V72.8182H648.957ZM686.244 72.8182H689.244V69.8182H686.244V72.8182ZM686.244 291V294H689.244V291H686.244ZM547.447 250.517V219.089H541.447V250.517H547.447ZM546.982 220.694L639.561 74.4226L634.491 71.2138L541.913 217.485L546.982 220.694ZM637.026 75.8182H663.233V69.8182H637.026V75.8182ZM660.233 72.8182V117.562H666.233V72.8182H660.233ZM663.233 114.562H647.253V120.562H663.233V114.562ZM644.716 115.961L582.393 214.719L587.467 217.921L649.79 119.164L644.716 115.961ZM581.93 216.32V218.024H587.93V216.32H581.93ZM584.93 221.024H714.156V215.024H584.93V221.024ZM711.156 218.024V250.517H717.156V218.024H711.156ZM714.156 247.517H544.447V253.517H714.156V247.517ZM651.531 291V240.929H645.531V291H651.531ZM651.53 241.02L651.956 226.957L645.959 226.776L645.533 240.838L651.53 241.02ZM651.957 226.866V72.8182H645.957V226.866H651.957ZM648.957 75.8182H686.244V69.8182H648.957V75.8182ZM683.244 72.8182V291H689.244V72.8182H683.244ZM686.244 288H648.531V294H686.244V288Z" fill="#373434" mask="url(#path-25-outside-2_1336_44451)"/>
                        <defs>
                        <clipPath id="clip0_1336_44451">
                        <rect width="265" height="278" fill="white" transform="translate(232 42.5)"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>

                <div className="flex justify-center">
                    <a href="/" className="h-[54px] w-[170px] mb-10 text-neutral-50 rounded-lg transition-colors bg-maroon hover:bg-maroon-light flex items-center justify-center">Back to home</a>
                </div>                
            </div>
        </SiteLayout>
    </>

}